.ov-btn-slide-left {
    justify-content: center; /* Centra horizontalmente */
    align-items: center; 
      color: #ffffff;
      height: 70px;
      border-radius: 12px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      display: inline-block;
      transition: all 0.35s;
    }
    
    .ov-btn-slide-left:hover {
      color: #fff;
    }
    
    .ov-btn-slide-left::after {
      content: "";
      background: #000000;
      position: absolute;
      z-index: -1;
      display: block;
      top: 0;
      bottom: 0;
      left: -100%;
      right: 100%;
      transition: all 0.50s;
    }
    
    .ov-btn-slide-left:hover::after {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transition: all 0.60s;
    }
    @media screen and (max-width: 768px) {
      .ov-btn-slide-left {
        height: 65px;
          font-size: 18px; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
      }
  }
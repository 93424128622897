.menu-phone {
    transition: height 0.3s;
    height: auto;
    z-index: 51;
  }

  .menu-container{
    z-index: 10;

  }
  
  .menu-phone a {
    text-align: left;
    font-size: 1.8rem; 
    margin: 10px 0; 
  }
  .menu-phone a:hover {
    color: #672398 ;
  }
  
  .menu-phone button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  .menu-phone button:hover {
    color: #672398 ;
  }

  .slide-in {
    animation: slideDown 0.9s ease forwards;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  }
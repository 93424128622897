@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.font-noto-200{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 200;
}

.font-noto-300{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 300;
}

.font-noto-italic-300{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 300;
    font-style:italic;
}

.font-noto-400{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
}
.font-noto-italic-400{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 400;
    font-style:italic;
}

.font-noto-500{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 500;
}
.font-noto-600{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 600;
}
.font-noto-700{
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: 700;
}
.font-plus-200{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 200;
}
.font-plus-300{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 300;
}
.font-plus-400{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
}
.font-plus-500{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
}
.font-inter-300{
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}
.font-inter-400{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}
.font-inter-500{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}
.font-inter-600{
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}


.font-poppins-200 {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
  }
.font-poppins-300 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }
.font-poppins-400 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
.font-poppins-500 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
.font-poppins-600 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
.font-poppins-700 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
  }
  
  .font-poppins-900 {
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
  }
  .font-plus-300 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 300;
  }
  .font-plus-400 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 400;
  }
  .font-plus-italic-400 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 400;
    font-style:italic;
  }
  .font-plus-500 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
  }
  .font-plus-600 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 600;
  }
  .font-plus-700 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
  }
  .font-plus-800 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 800;
  }

  .font-roboto-condensed-700 {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
  }
  .font-lato-500 {
    font-family: "Lato", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .font-lato-600 {
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  .font-lato-700 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
.logo{
    max-width: 70px; 
    height: auto; 
}

@media screen and (max-width: 600px) {
    .logo {
        max-width: 50px; 
        height: auto; 
    }
  }
.shadow-place {
  background-color: rgba(63, 7, 98, 0.8);
}

@media screen and (max-width: 639px) {
 
}
@media (min-width: 401px) and (max-width: 600px) {
  /* .header {
    height: 34vh; 
  } */
  .wave {
    bottom: -5px;
  }
}
@media (min-width: 600px) and (max-width: 769px) {
  /* .header {
    height: 34vh; 
  } */
  .wave {
    bottom: -5px;
  }
}

@media (min-height: 700px) and (min-width: 1280px) {
  /* .header {
    height: 50vh; 
  } */
  .wave {
    bottom: -5px;
  }
}

@media (min-height: 500px) and (min-width: 1078px) and (max-width: 1279px) {
  /* .header {
    height: 87vh; /* Cambia el alto a 60vh para pantallas más pequeñas */
  /* } */
  .wave {
    bottom: -5px;
  }
}
@media (min-height: 500px) and (min-width: 1024) and (max-width: 1077px) {
  /* .header {
    height: 96vh; /* Cambia el alto a 60vh para pantallas más pequeñas */

  .wave {
    bottom: -5px;
  }
  .titulo {
    font-size: 48px;
  }
}
